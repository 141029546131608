<template>
  <div :class="role ? 'container_is_auth' : 'container_no_auth'">
    <Nav v-if="!role" />
    <div :class="role ? 'child_is_auth' : 'child_no_auth'" v-if="!loading">
      <div v-if="surveys">
        <div>
          <v-card class="radius-card" elevation="0">
            <div
              :class="
                surveys.kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : surveys.kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__'
              "
              style="height: 15px; width: 100%"
            ></div>
            <div class="pa-5 mb-5">
              <h1 class="mb-2">{{ surveys.judul }}</h1>
              <p class="ma-0">{{ surveys.keterangan }}</p>
            </div>
          </v-card>
          <v-card
            class="radius-card py-3 px-5 mb-3"
            elevation="0"
            v-if="profile"
          >
            <v-row>
              <v-col cols="12" md="3"><p class="mt-2">Nama lengkap</p></v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-if="profile.nama_lengkap"
                  label="Nama Lengkap"
                  dense
                  v-model="profile.nama_lengkap"
                ></v-text-field>
                <v-text-field
                  v-else
                  label="Nama Lengkap"
                  dense
                  v-model="fullname"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3"><p class="mt-2">Email</p></v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  label="Email"
                  dense
                  v-model="profile.email"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
          <div
            v-for="(relationship, idx) in surveys.relationships"
            :key="`xX${idx}Xx`"
          >
            <div v-if="relationship.soal">
              <v-card
                class="radius-card px-5 pt-5 mb-5"
                v-for="question in relationship.soal.data"
                :key="`quest-${question.id}`"
                elevation="0"
              >
                <v-row>
                  <v-col cols="12" md="11">
                    <h3>{{ question.soal }}</h3>
                  </v-col>
                </v-row>
                <div class="py-3">
                  <section v-if="question.tipe == 'single'">
                    <!-- <div v-for="(val, key) in question.pilihan" :key="key">
                      <v-row>
                        <v-col cols="6" md="1">
                          <input
                            @click="getAnswer(question.id, key)"
                            type="radio"
                            :name="question.id"
                            :id="`radio-${question.id}-${key}`"
                            class="custom-radio"
                          />
                        </v-col>
                        <v-col cols="6" md="5">{{ val }}</v-col>
                      </v-row>
                    </div> -->
                    <v-radio-group>
                      <v-radio
                        v-for="(val, key) in question.pilihan"
                        :key="key"
                        :label="val"
                        :value="key"
                        :id="`radio-${question.id}-${key}`"
                        @click="getAnswer(question.id, key)"
                      ></v-radio>
                    </v-radio-group>
                  </section>
                  <section class="my-3" v-if="question.tipe == 'multiple'">
                    <v-checkbox
                      v-for="(val, key) in question.pilihan"
                      :key="`multi${key}`"
                      :label="val"
                      hide-details
                      :value="val"
                      class="ma-1"
                    ></v-checkbox>
                  </section>
                </div>
              </v-card>
            </div>
          </div>
          <div v-if="surveys.status == 0">
            <v-btn
              depressed
              :color="
                surveys.kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : surveys.kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__'
              "
              dark
              v-if="!loadSave"
              @click="saveAnswer"
            >
              <b>Kirim</b>
            </v-btn>

            <v-btn
              depressed
              :color="
                surveys.kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : surveys.kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__'
              "
              dark
              v-if="loadSave"
            >
              <b>Loading..</b>
            </v-btn>
          </div>
          <v-btn
            v-if="surveys.user_id == id"
            depressed
            color="cyan"
            dark
            :to="`/survey/${surveys.id}/update`"
            class="mt-2"
          >
            <b>Edit Survey</b>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="_100top pa-3 _full-w" v-if="loading">
      <h3>Loading...</h3>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import Nav from "../../components/General/Nav.vue";

export default {
  components: { Nav },
  name: "viewSURVEY",
  computed: {
    ...mapState({
      surveys: state => state.survey.survey_byid,
      env: state => state.API_URL,
      id: state => state.id,
      role: state => state.role,
      profile: state => state.psycholog.myProfile
    })
  },
  data() {
    return {
      loading: false,
      fullname: "",
      email: "",
      answer: [],
      loadSave: false,
      isAnswered: false
    };
  },

  mounted() {
    this.fetch();
    // window.onbeforeunload = function () {
    //   return "Jawaban anda belum tersimpan";
    // };
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next) {
    if (!this.isAnswered) {
      Swal.fire({
        //validation if user want to navigate route
        title: "Yakin keluar survey?",
        text: "Jawaban anda tidak akan disimpan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya"
      }).then(result => {
        if (result.isConfirmed) {
          next();
        }
      });
    } else {
      next();
    }
  },
  methods: {
    fetch() {
      this.loading = true; //set loading to true
      let id = this.$route.params.id;
      console.log(this.$route.params.visible);
      this.$store.dispatch("survey/getSurvey", id).then(() => {
        this.loading = false; //if request api get succes set loading to false
      });
    },
    checkAnswer() {
      let data = this.surveys.relationships.filter(el => {
        return el.jawaban;
      });
      let isAnswerExist = data[0].jawaban.data;
      if (isAnswerExist.length) {
        let dataAnswerUser = isAnswerExist.find(el => {
          return el.email === this.profile.email;
        });
        console.log(dataAnswerUser);
        document.getElementById("radio-34-A").ariaChecked = true;
        console.log(document.getElementById("radio-34-A"));
      }
    },
    getAnswer(id, idx) {
      let exsist = this.answer.filter(el => el.id == id); //check is id soal exist in answer array?
      if (exsist.length) {
        let index = this.answer.findIndex(el => el.id == id); //get index exist id
        this.answer.splice(index, 1); //remove old answer
        this.answer.push({
          //and push new answer
          id: id,
          answer: idx
        });
      } else {
        this.answer.push({
          // push new answer to array
          id: id,
          answer: idx
        });
      }
    },
    saveAnswer() {
      this.loadSave = true;
      let answer = {
        nama: this.profile.nama_lengkap
          ? this.profile.nama_lengkap
          : this.fullname,
        email: this.profile.email,
        jawaban: this.answer
      };

      console.log(this.answer);
      let data = {
        id: this.$route.params.id,
        answer: answer
      };
      this.$store
        .dispatch("survey/storeAnswer", data)
        .then(data => {
          this.isAnswered = true;
          this.loadSave = false;
          this.$router.push("/psikolog/survey/thanks");
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          this.loadSave = false;
          Swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        });
    }
  }
};
</script>

<style scoped>
.slash__ {
  height: 15px;
  width: 100%;
}
.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 100px;
  padding: 10px;
  margin: 90px auto;
  width: 70%;
}
.child_no_auth {
  margin-top: 100px !important;
  padding: 10px;
  margin: 0 auto;
  width: 70%;
}
.custom-radio {
  width: 1.3em !important;
  height: 1.3em !important;
  vertical-align: top !important;
  margin-right: 1% !important;
  margin-top: 0.3% !important;
}
@media (max-width: 768px) {
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 369px) {
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
}
</style>
